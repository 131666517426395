.ChatOptionMobile {
    padding: 4px 18px;
    /* width: 149px; */
    height: 41.83px;
    position: fixed;
    bottom: 1.25rem;
    right: 24px;
    display: flex;
    z-index: 99;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90.56deg, #F21623 -27.33%, #EA4B54 137.21%);
    box-shadow: 1.94555px 3.89109px 13.6188px rgba(0, 0, 0, 0.25);
    border-radius: 39.8837px;
}
.ChatOptionMobile p{
	font-family: 'Avenir LT Std';
font-style: normal;
font-weight: 700;
font-size: 15.3659px;
line-height: 156.5%;
color: #FFFFFF;
margin-bottom: 0px;
}
.banner {
	/* padding-top: 2rem; */
	/* padding-bottom: 2rem; */
}
.banner .landing {
	padding: 60px 10rem 0 10rem;
}
.bannerIcons{
	width: 75%;
	margin-right: 3rem;
}
.banner .tagline {
	font-family: "Avenir LT Std";
	font-style: normal;
	/* font-weight: 400; */
	font-size: 28px;
	line-height: 156.5%;
	margin-top: 24px;
	margin-bottom: 24px;
	/* identical to box height, or 44px */
	font-feature-settings: "tnum" on, "lnum" on;

	color: #626262;
}

.banner .gradient-text {
	/* font-weight: 500; */
	background-color: #fc1255;
	background-image: linear-gradient(
		135deg,
		#fc1255,
		#fc1255,
		#0481ff,
		#535bff
	);
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
/* .BannnerSectionMeditation,.BannnerSectionTimer{
	margin-top: 27px;
} */
.banner .description {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	/* Black */
	color: #212121;

	opacity: 0.7;
}

.banner .briefIdea {
    font-family: "Avenir LT Std";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 156.5%;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #212121;
    margin-top: 18px;
}
.banner .stats {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 60px;
}
.banner .stats .stateBox .number {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;

	/* identical to box height, or 50px */
	text-align: center;
	font-feature-settings: "tnum" on, "lnum" on;

	color: #626262;
	padding: 1rem 0 0.2rem;
}
.banner .stats .stateBox .brief {
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 156.5%;

	/* or 28px */
	text-align: center;

	/* Black */
	color: #212121;
}

.banner .featureBrief {
	
}
/* .featureBrief div{
padding-top: 1.5rem;
} */
/* .banner video {
	height: max-content !important;
} */
.ReactPlayer .react-player__preview {
    background-size: cover !important;
}
/* .ReactPlayer{
border-radius: 10px;
       -moz-border-radius: 10px;}
.ReactPlayer video{
	object-fit: cover !important;
} */
.react-player__preview{
	border-radius: 10px !important;
	-moz-border-radius: 10px !important;
	
}


.redBannerHeader .redBannerContainer{
	display: flex;
	justify-content: center;
	margin-top: 60px;
	text-align: center;
}
.redBannerHeader .redBannerContainer h2{
	width: 70%;
	margin-bottom: 30px;
	font-weight: 750;
	font-size: 32px;
	line-height: 156.5%;
	font-style: normal;
    font-feature-settings: "tnum" on,"lnum" on;
	color: #212121;
}
.bannerVideo {
	/* aspect-ratio: 16 / 9;
	min-height: 295px;
	object-fit: contain; */
}
@media only screen and (min-width: 1500px) {
	.bannerVideo {
		/* aspect-ratio: 16 / 9;
		min-height: 495px;
		object-fit: contain; */
	}
}

@media only screen and (max-width: 991px) {
		.banner .landing {
			padding: 60px 0rem 0 0rem ;
		}
	
}

@media only screen and (max-width: 768px) {
	.redBannerHeader .redBannerContainer{
		margin-top: 48px;
	}
	.banner .landing {
		padding: 60px 0rem 0 0rem ;
	}
	.redBannerHeader .redBannerContainer h2{
		width: 100%;
		margin-bottom: 30px;
		font-weight: 700;
		font-size: 20px;
		line-height: 156.5%;
	}
}
@media only screen and (max-width: 600px) {
	/* .featureBrief div{
			padding-top: 3rem!important;
			padding-bottom: 3rem!important;
		
	} */
	.ReactPlayer .react-player__preview{
		background-position: right !important;
	}
	.FounderMessage{
		font-family: 'Avenir LT Std';
font-style: normal;
font-weight: 750;
font-size: 20px;
line-height: 156.5%;
/* or 31px */


text-align: center;
font-feature-settings: 'tnum' on, 'lnum' on;

/* Black */

color: #212121;

	}

	.redBannerHeader .redBannerContainer h2{
		width: 100%;
		margin-bottom: 0px;
		font-weight: 700;
		font-size: 20px;
		line-height: 156.5%;
	}
	.banner .landing {
		padding: 48px 0rem 0 0;
	}
	/* .banner .featureBrief {
		margin-top: 24px;
	} */
	.banner .stats {
		margin-top:48px;
	}
	.banner .stats .stateBox .number {
		font-weight: 750;
		font-size: 16px;
	}
	.banner .stats .stateBox .brief {
		font-weight: 600;
		font-size: 12px;
	}
	.banner .tagline {
		font-size: 20px;
	}
	.banner .description {
		font-size: 14px;
	}
	.banner .briefIdea {
		font-size: 14px;
	}
	.bannerIcons{
		width: 100%;
		margin-right: 0px;
	}

}

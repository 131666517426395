.HomeBanner{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0px 1rem;
}

.yogaClassDescription{
    width: 60%;
}
.studioSession{
    display: flex;
}
.studioSession h3{
    font-family: "AvenirMedium";
    font-size: 32px;
    font-weight: 750;
    background: #FCE2E3;
    border-radius: 4px;
    color: #212121;
    padding: 8px;
    margin-bottom: 8px;
}
.yogaClassDescription h2{
    font-style: normal;
    font-weight: 750;
    font-size: 45px;
    line-height: 156.5%;
    margin-bottom: 0px;
    font-style: normal;
    font-feature-settings: "tnum" on,"lnum" on;
}
.yogaClassDescription h2 span{
    color: #FE5266;
}

.yogaClassDescription .para{
    font-size: 18px;
    color: #212121;
    opacity: 0.7;
    margin-top: 13px;
}

.yogaClassDescription .benefitsContainerParent{
    height: 100%;
}
.HomeBanner .studioImg{
    width: 35%;
}
.HomeBanner .studioImg img{
    height: 740px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.benefitsContainerParent{
    height: 100%;
}
.benefitsContainer{
    width: 100%;
    margin-top: 40px;
    display: flex;
    /* gap: 20px; */
    justify-content: space-between;
}
.benefitsContainer .benefits{
    display: flex;
    width: 48%;
    align-items: flex-start;
}
.benefitsContainer .benefits .benefitsPara{
    margin-left: 12px;
}
.benefitsContainer .benefits .benefitsPara h4{
    font-weight: 750;
    font-size: 18px;
}

.benefitsContainer .benefits .benefitsPara span{
    font-weight: 300;
    font-size: 16px;
}
.studioImgResp{
    display: none;
}

@media screen and (max-width: 766px) {
    .HomeBanner{
        padding: 0px;
        margin-top: 48px;
    }
    .studioSession h3{
        font-size: 16px;
        font-weight: 750;
        background: #FCE2E3;
        border-radius: 4px;
        color: #212121;
        padding: 8px;
        margin-bottom: 8px;
    }
    .studioImg{
        display: none;
    }
    .yogaClassDescription{
        width: 100%;
    }
    .yogaClassDescription h2{
        font-size: 20px;
        /* text-align: center; */
    }
    .yogaClassDescription .para{
        font-size: 14px !important;
        /* text-align: center; */
    }
    .benefitsContainer{
        flex-direction: column;
        margin-top: 0px;
        gap: 0px;
    }

    .benefitsContainer .benefits{
        width: 100%;
        margin-top: 20px;
    }
    .benefitsContainer .benefits img{
        width: 28px;
        height: 28px;
    }

    .benefitsContainer .benefits .benefitsPara h4{
        font-weight: 750;
        font-size: 16px;
        margin-bottom: 0px;
    }
    
    .benefitsContainer .benefits .benefitsPara span{
        font-weight: 300;
        font-size: 14px;
    }
    .studioImgResp{
        display: flex;
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    .studioImgResp img{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 900px) {
    .HomeBanner{
        padding: 0px;
        margin-top: 48px;
    }
    .studioImg{
        display: none;
    }
    .studioSession p{
        font-size: 16px;
        font-weight: 750;
        background: #FCE2E3;
        border-radius: 4px;
        color: #212121;
        padding: 8px;
        margin-bottom: 8px;
    }
    .yogaClassDescription{
        width: 100%;
    }
    .yogaClassDescription h2{
        font-size: 20px;
        /* text-align: center; */
    }
    .yogaClassDescription .para{
        font-size: 14px;
        /* text-align: center; */
    }
    .benefitsContainer{
        display: flex;
        
    }

    .benefitsContainer .benefits{
        width: 100%;
    }
    .benefitsContainer .benefits img{
        width: 32px;
        height: 32px;
    }

    .benefitsContainer .benefits .benefitsPara h4{
        font-weight: 750;
        font-size: 16px;
        margin-bottom: 0px;
    }
    
    .benefitsContainer .benefits .benefitsPara span{
        font-weight: 300;
        font-size: 14px;
    }
    .studioImgResp{
        display: flex;
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    .studioImgResp img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}